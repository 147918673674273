@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf?dff4b02aa5303c09da09de9ef80ed580") format("truetype"),
url("../fonts/flaticon.woff?dff4b02aa5303c09da09de9ef80ed580") format("woff"),
url("../fonts/flaticon.woff2?dff4b02aa5303c09da09de9ef80ed580") format("woff2"),
url("../fonts/flaticon.eot?dff4b02aa5303c09da09de9ef80ed580#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg?dff4b02aa5303c09da09de9ef80ed580#flaticon") format("svg");
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-air-conditioner:before {
    content: "\f101";
}
.flaticon-cable-tv:before {
    content: "\f102";
}
.flaticon-arrow-down:before {
    content: "\f103";
}
.flaticon-iron:before {
    content: "\f104";
}
.flaticon-terrace:before {
    content: "\f105";
}
.flaticon-right-arrow-angle:before {
    content: "\f106";
}
.flaticon-kettle:before {
    content: "\f107";
}
.flaticon-down-chevron:before {
    content: "\f108";
}
.flaticon-hair-dryer:before {
    content: "\f109";
}
.flaticon-selection:before {
    content: "\f10a";
}
.flaticon-phone-ringing:before {
    content: "\f10b";
}
.flaticon-building:before {
    content: "\f10c";
}
.flaticon-mail:before {
    content: "\f10d";
}
.flaticon-facebook-logo:before {
    content: "\f10e";
}
.flaticon-office:before {
    content: "\f10f";
}
.flaticon-instagram:before {
    content: "\f110";
}
.flaticon-add-circular-outlined-button:before {
    content: "\f111";
}
.flaticon-up-circular-interface-button:before {
    content: "\f112";
}
.flaticon-remove:before {
    content: "\f113";
}
.flaticon-right-arrow:before {
    content: "\f114";
}
.flaticon-right-arrow-1:before {
    content: "\f115";
}
.flaticon-wifi-signal:before {
    content: "\f116";
}
.flaticon-car:before {
    content: "\f117";
}
.flaticon-pool:before {
    content: "\f118";
}
.flaticon-phone:before {
    content: "\f119";
}
.flaticon-breakfast:before {
    content: "\f11a";
}
.flaticon-call:before {
    content: "\f11b";
}
.flaticon-bath:before {
    content: "\f11c";
}
.flaticon-smart-tv:before {
    content: "\f11d";
}
.flaticon-checkmark:before {
    content: "\f11e";
}
.flaticon-bath-1:before {
    content: "\f11f";
}
.flaticon-water-waves:before {
    content: "\f120";
}
.flaticon-blueprint:before {
    content: "\f121";
}
.flaticon-kitchen-set:before {
    content: "\f122";
}
.flaticon-house-keeping:before {
    content: "\f123";
}
.flaticon-resort:before {
    content: "\f124";
}
.flaticon-smartphone:before {
    content: "\f125";
}
.flaticon-shopping-cart:before {
    content: "\f126";
}
.flaticon-bed:before {
    content: "\f127";
}
.flaticon-calendar:before {
    content: "\f128";
}
.flaticon-bell:before {
    content: "\f129";
}
.flaticon-left-arrow:before {
    content: "\f12a";
}
.flaticon-conference:before {
    content: "\f12b";
}
.flaticon-map:before {
    content: "\f12c";
}
.flaticon-fruit:before {
    content: "\f12d";
}
.flaticon-dumbbell:before {
    content: "\f12e";
}
.flaticon-wifi:before {
    content: "\f12f";
}
.flaticon-email:before {
    content: "\f130";
}
.flaticon-call-1:before {
    content: "\f131";
}
.flaticon-location:before {
    content: "\f132";
}
.flaticon-clock:before {
    content: "\f133";
}
.flaticon-weights:before {
    content: "\f134";
}
.flaticon-game-controller:before {
    content: "\f135";
}
.flaticon-plane:before {
    content: "\f136";
}
.flaticon-massage:before {
    content: "\f137";
}
.flaticon-cheers:before {
    content: "\f138";
}
.flaticon-laundry:before {
    content: "\f139";
}
.flaticon-conference-1:before {
    content: "\f13a";
}
.flaticon-bath-tub:before {
    content: "\f13b";
}
.flaticon-tray:before {
    content: "\f13c";
}
.flaticon-martini:before {
    content: "\f13d";
}
.flaticon-croissant:before {
    content: "\f13e";
}
.flaticon-pin:before {
    content: "\f13f";
}
.flaticon-calendar-1:before {
    content: "\f140";
}
.flaticon-people:before {
    content: "\f141";
}
.flaticon-happy:before {
    content: "\f142";
}
.flaticon-user:before {
    content: "\f143";
}
.flaticon-preview:before {
    content: "\f144";
}
.flaticon-view:before {
    content: "\f145";
}
.flaticon-date:before {
    content: "\f146";
}
.flaticon-room:before {
    content: "\f147";
}
.flaticon-location-1:before {
    content: "\f148";
}
.flaticon-dollar:before {
    content: "\f149";
}
.flaticon-instagram-1:before {
    content: "\f14a";
}
.flaticon-open-menu:before {
    content: "\f14b";
}
.flaticon-plus:before {
    content: "\f14c";
}
.flaticon-minus:before {
    content: "\f14d";
}
.flaticon-quote:before {
    content: "\f14e";
}
.flaticon-down-arrow:before {
    content: "\f14f";
}
.flaticon-open:before {
    content: "\f150";
}
.flaticon-call-2:before {
    content: "\f151";
}
.flaticon-location-2:before {
    content: "\f152";
}
.flaticon-send:before {
    content: "\f153";
}
.flaticon-facebook:before {
    content: "\f154";
}
.flaticon-twitter:before {
    content: "\f155";
}
.flaticon-bed-1:before {
    content: "\f156";
}
.flaticon-like:before {
    content: "\f157";
}
.flaticon-check:before {
    content: "\f158";
}
.flaticon-moon:before {
    content: "\f159";
}
.flaticon-traffic-jam:before {
    content: "\f15a";
}
.flaticon-behance:before {
    content: "\f15b";
}
.flaticon-chat:before {
    content: "\f15c";
}
.flaticon-linkedin:before {
    content: "\f15d";
}
.flaticon-youtube:before {
    content: "\f15e";
}
.flaticon-loupe:before {
    content: "\f15f";
}
.flaticon-pinterest-social-logo:before {
    content: "\f160";
}
.flaticon-edit:before {
    content: "\f161";
}
.flaticon-mail-1:before {
    content: "\f162";
}
.flaticon-internet:before {
    content: "\f163";
}
.flaticon-right-arrow-2:before {
    content: "\f164";
}
.flaticon-time:before {
    content: "\f165";
}
