.offers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-offer {
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}

.offer-content {
  box-shadow: var(--box-shadow-1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  width: 100%;
  padding: 20px;
}

.single-offer:first-child {
  margin-top: 36px;
}

.single-offer:last-child {
  margin-bottom: 36px;
}

.single-offer img {
  /* width: 100%; */
  margin: 0 auto;
  margin-bottom: 18px;
  border-radius: 10px;
}

.offer-title {
  font-size: 2.5rem;
  line-height: 4rem;
  align-self: center;
  padding-top: 12px;
  color: black;
}

.offers-container .line-separator {
  margin: 40px 40px;
  align-self: center;
  width: 80%;
}

.no-available-offers {
  align-self: center;
  margin: 0 auto;
  display: block;
  width: fit-content;
  margin-top: 50px;
  margin-bottom: 50px;
}

.page-title.no-text {
  background-size: contain !important;
  padding: 150px 0px 130px;
}

@media screen and (min-width: 992px) {
  .offers-container {
    align-items: center;
  }

  .single-offer {
    /* flex-direction: row; */
    width: 70%;
  }

  .single-offer img {
    margin: unset;
    margin-bottom: 12px;
    /* height: 24rem; */
    align-self: center;
  }
}

@media screen and (min-width: 550px) {
  .page-title.no-text {
    padding: 300px 0px 130px;
  }
}

@media screen and (min-width: 1200px) {
  .page-title.no-text {
    padding: 700px 0px 130px;
  }
}