.main-button {
  background: var(--theme-color);
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
  border: 0;
  outline: 0;
  max-width: 180px;
  min-width: 90px;
  height: 50px;
  padding: 7px;
  border-radius: 8px;
}
