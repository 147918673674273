.admin-room-page {
  min-height: 100vh;
  padding-bottom: 100px;
}

.add-availability-header {
  text-align: center;
  margin-bottom: 0;
}

.admin-room-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  padding-top: 10px;
  height: 100%;
}

.admin-room-page__select-wrapper {
  width: 100%;
  max-width: 340px;
}

.rooms-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 60px;
}

.room-display-container {
  width: 300px;
  border: 1px solid #ccc;
  padding: 7px;
  margin: 7px;
}

.room-display-image {
  margin: 10px 0;
}

.change-room-price-button {
  margin: 10px auto;
  display: block;
}

.change-room-price-input {
  width: 60px;
  border: 1px solid #f3f3f3;
  text-align: center;
  display: block;
}

.change-room-price-input:focus {
  border: 1px solid var(--theme-color);
}

.price-period {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.admin-room-page__datepicker {
  width: 100%;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.admin-room-page__datepicker i::before {
  font-size: 22px;
  margin-right: 10px;
}

.react-datepicker-wrapper input {
  width: 95%;
  margin-right: 10px;
}

.admin-room-page__price-container {
  width: 90%;
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-room-page__price-container p {
  margin-bottom: 5px;
}

.admin-room-page__price-input {
  width: 90px;
  max-width: 340px;
  border: 1px solid var(--theme-color);
  text-align: center;
}
.admin-room-page__submit-button {
  display: block;
  margin: 0 auto;
}

.availability-table {
  margin: 50px auto;
}

.availability-table th,
.availability-table td {
  border: 1px solid var(--theme-color);
  padding: 10px;
  text-align: center;
  max-width: 40vw;
}

.delete-button {
  background-color: #f16464;
}

.availability-see-details {
  cursor: pointer;
}

.display-availability-section {
  margin-bottom: 50px;
}

.draggable-carousel-item {
  border: 1px solid var(--theme-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.draggable-carousel-item p {
  margin: 0;
  text-align: start;
  width: 100%;
}

.carousel-input-group {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
}

.carousel-input-group input {
  width: 20%;
  border: none;
  border-bottom: 1px solid var(--theme-color);
  text-align: center;
}

.availability-details-container {
  border: 1px solid var(--theme-color);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 18px;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  height: 400px;
  overflow-x: auto;
  place-items: center;
}

.save-button {
  width: 200px;
  height: 45px;
  position: sticky;
  bottom: -10px;
  left: 40%;
}

.rooms-availability-red-text {
  background-color: var(--color-red-1);
}

@media screen and (min-width: 768px) {
  .admin-room-page-content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .admin-room-page__select-wrapper {
    min-width: 100px;
  }
  .admin-room-page__datepicker,
  .admin-room-page__price-container {
    width: auto;
    margin: 10px 15px;
  }
}
