.line-separator {
  width: 100%;
  border-bottom: 1px solid var(--theme-color);
  position: relative;
  margin: 20px 0;
}

.line-separator::before {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  bottom: -8px;
  margin-left: -8px;
  border: 1px solid #ffffff;
  background: #ffffff;
  width: 15px;
  height: 15px;
  -webkit-box-shadow: 0 0 0 1px var(--theme-color),
    inset 0 0 0 2px var(--theme-color);
  box-shadow: 0 0 0 1px var(--theme-color), inset 0 0 0 2px var(--theme-color);
}

.line-separator::after {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  bottom: 1px;
  margin-left: -46%;
  z-index: -1;
  background: var(--theme-color);
  width: 92%;
  height: 1px;
}
