.reservations-table {
  width: 90%;
  margin: 25px auto;
}

.reservations-table th,
.reservations-table td {
  border: 1px solid var(--theme-color);
  padding: 5px;
  text-align: center;
  max-width: 40vw;
}

.reservations-table a {
  color: var(--toastify-color-info);
}

.report-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 12px;
}

.report-wrapper .main-button {
  margin: 12px 0;
}

.report-wrapper .admin-room-page__select-wrapper {
  padding-top: 12px;
}

.reservation-tabs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.reservation-tab {
  padding: 1rem;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid var(--theme-color);
}

.reservation-tab:first-of-type {
  margin-right: 1rem;
}

.reservation-tab--active {
  background-color: var(--theme-color);
  color: var(--white-color);
}
