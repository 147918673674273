.subscribers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subscribers-table {
  margin-top: 18px;
}

.subscribers-table th,
.subscribers-table td {
  border: 1px solid var(--theme-color);
  padding: 10px;
  text-align: center;
  max-width: 40vw;
  margin: auto;
  display: block;
  min-width: 320px;
}

.subscribers-table h3 {
  word-break: break-all;
}
