.payment-page {
  margin: 60px 0;
}

.food-board-container {
  align-items: centers;
  box-shadow: 0 10px 40px 0 rgb(50 50 50 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 36px;
  max-width: 700px;
  width: 90%;
}

.payment-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
}

.payment-container h2,
.payment-form h2 {
  margin-bottom: 15px;
}

.payment-page .line-separator {
  width: 80%;
  display: block;
  margin: 40px auto;
}

.booking-details {
  align-items: center;
  box-shadow: 0 10px 40px 0 rgb(50 50 50 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 700px;
  width: 90%;
}

.booking-details__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.booked-rooms-summary {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 7px;
}

.booked-rooms-summary p {
  text-align: center;
}

.voucher-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-and-voucher__submit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.food-board-container h2,
.voucher-form h2 {
  margin-bottom: 18px;
}

.booking-details__item {
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-new-voucher-modal__content h3 {
  text-align: center;
}

.payment-page .payment-form {
  width: 90%;
  display: block;
  margin: 20px auto;
  box-shadow: 0 10px 40px 0 rgb(50 50 50 / 10%);
  padding: 20px;
  max-width: 700px;
}

.payment-form h5 {
  text-align: center;
  margin-bottom: 10px;
}

.payment-form .form-submit button {
  background: var(--theme-color);
  color: white;
  margin: 0 auto;
  display: block;
}

.payment-form .card-details,
.payment-form input,
.voucher-form input {
  border: 1px solid #dddddd;
  padding: 15px;
  border-radius: 7px;
}

.payment-heading {
  text-align: center;
}

.payment-and-voucher-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.voucher-form {
  background-color: white;
  box-shadow: 0 10px 40px 0 rgb(50 50 50 / 10%);
  padding: 30px;
  width: 90%;
  min-width: 280px;
}

.submit-voucher-button {
  margin-top: 30px;
  border-radius: 8px;
  padding: 10px;
}

.voucher-error {
  color: var(--color-red-1);
  padding-top: 12px;
}

@media screen and (min-width: 768px) {
  .booking-details__items {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .total-price {
    width: 100%;
  }
  .total-price .price {
    display: block;
    margin: 0 auto;
    width: 220px;
  }
  .voucher-form {
    max-width: 700px;
  }
  .payment-and-voucher__submit-container {
    flex-direction: row;
  }
  .submit-voucher-button {
    margin-left: 30px;
    margin-top: 0;
  }

  .flex-breaker {
    flex-basis: 100%;
    margin: 0;
    border: 0;
    height: 0;
    content: "";
  }
  .booked-rooms-summary {
    margin: 12px 0;
  }
}

@media screen and (min-width: 992px) {
  .payment-and-voucher-container {
    margin: 0 auto;
  }
  .payment-page .payment-form,
  .voucher-form {
    width: 100%;
  }
}

@media screen and (min-width: 1100px) {
  .booking-details__items {
    justify-content: center;
    max-width: 700px;
  }
  .booking-details__item {
    margin: 0 15px;
  }
}
