.cancellation-page {
  flex: 1;
}
.cancellation-container {
  /* min-height: 50vh; */
  padding: 10% 0;
}
.cancellation-form {
  width: 50%;
  margin: 25px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cancellation-form input {
  margin-bottom: 18px;
  font-size: 20px;
  border: 2px solid var(--theme-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 18px;
  color: #283a5e;
  padding: 10px;
}

.cancellation-page .loading-spinner-container {
  height: 60px;
  margin-top: 0;
}
