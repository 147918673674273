.admin-navbar {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.admin-navbar li {
  padding: 12px;
}

.admin-navbar li:hover {
  cursor: pointer;
}

.admin-navbar li:hover,
.admin-tab-active {
  color: var(--theme-color);
}

.nav-active a {
  color: var(--color-primary-1);
}

.navlist .icon {
  width: 18px;
  height: 18px;
}

.translation-dropdown {
  width: 40px;
  height: 20px;
  transform: translateX(-7px);
}

.translation-dropdown li {
  cursor: pointer;
  width: 100%;
  padding: 5px;
  text-align: center;
}

.translation-dropdown li p {
  margin-bottom: 0;
}

.translation-dropdown li:hover p {
  color: #cdb5a7;
}

@media screen and (min-width: 1000px) {
  .translation-list-item {
    margin-left: 10px;
  }
}
