.admin-all-offer-page {
  min-height: 100vh;
}

.admin-all-offers-container {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
}

.offer-container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-width: 360px;
  min-width: 300px;
  border: 1px solid var(--theme-color);
  margin: 10px;
}

.offer-container h3 {
  font-family: "Quicksand", sans-serif;
}

.offer-container img {
  width: 100%;
}

.offer-container__tools {
  display: flex;
  width: 100%;
  padding: 10px 0;
}

.offer-container__title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 7px;
}

.offer-container__title h3 {
  width: 80%;
  word-break: break-all;
  text-align: left;
}

.delete-button-container {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.offer-edit-btn {
  background-color: var(--toastify-color-info);
  margin-right: 10px;
}

.delete-offer-button {
  align-items: center;
  background: #f16464;
  display: flex;
  justify-content: center;
  color: white;
  cursor: pointer;
  width: 30px;
}

.delete-offer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-offer-modal__content {
  background-color: white;
  width: 500px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.delete-offer-modal__content-buttons {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirm-delete-button {
  background-color: #f16464;
}

.offer-status-btn {
  margin: 0 10px;
}
