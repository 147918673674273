.create-offer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-offer-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.create-offer-form__title,
.create-offer-form__text {
  width: 400px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #f8f8f8;
  background-color: #f8f8f8;
  padding: 5px 15px;
}

.create-offer-form__title:focus,
.create-offer-form__text:focus {
  outline: none !important;
  border: 1.5px solid var(--theme-color);
}

.loading-spinner {
  width: 60px;
  height: 60px;
}

.offer-image {
  width: 400px;
  max-height: 500px;
  margin-bottom: 15px;
}

.create-offer__image-label {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 125px;
  height: 45px;
  border-radius: 8px;
  background-color: rgb(29, 136, 207);
  font-size: 16px;
  text-align: center;
  padding: 20px;
  color: white;
  line-height: 15px;
}

.create-ofer-form__image-input {
  display: none;
}
