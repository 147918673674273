.room-details-services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 18px;
}

.col-lg-8 .block-forty .pricing {
  background-color: var(--theme-color);
  color: white;
  padding: 10px 17px;
  text-align: center;
  margin-bottom: 7px;
  width: 240px;
}

.reserve-room-button {
  border-radius: 0;
  padding: 10px 17px;
  min-width: 240px;
  height: 60px;
  background-color: var(--color-black-matte);
  color: "white";
  transition: all 0.5s;
}

.reserve-room-button:hover {
  color: var(--theme-color);
  background-color: #211f1f;
}

.room-details__heading {
  margin-top: 12px;
}

@media screen and (min-width: 768px) {
  .reserve-room-button {
    display: inline-block;
  }

  .col-lg-8 .block-forty .pricing {
    display: inline-block;
  }
}