.gallery-container {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .gallery-filters {
    margin-bottom: 30px;
  }

  .Room-area.section-padding,
  .Room-area2.section-padding {
    padding-bottom: 70px;
  }
}

.gallery-filters .nav-tabs,
.gallery-filters .nav-tabs .nav-link {
  color: var(--theme-color);

  border: 0 !important;
}

.gallery-filters .nav-tabs .nav-item.show .nav-link {
  font-weight: 500;
  display: block;
  text-transform: capitalize;
  border: 0 !important;
  cursor: pointer;
}

.gallery-filters .nav-tabs .nav-link.active,
.gallery-filters .nav-tabs .nav-link:hover,
.gallery-filters .nav-tabs .nav-link:focus {
  border: 0 !important;
  cursor: pointer;
  color: white;
}

.gallery-filters .nav-tabs .nav-link.active {
  color: white;
  text-decoration: none;
}

@media (max-width: 767px) {

  .gallery-filters .nav-tabs .nav-item.show .nav-link,
  .gallery-filters .nav-tabs .nav-link.active {
    font-size: 15px;
    font-size: 1rem;
  }
}

.gallery-container .active {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.gallery-container .gallery-item {
  width: 80%;
  margin-bottom: 14px;
  margin-left: 10px;
  /* margin-right: 7px; */
  overflow: hidden;
}

.gallery-container .gallery-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1280px) {
  .gallery-container .gallery-item img {
    height: 280px;
  }
}

@media screen and (max-width: 768px) {
  .gallery-container .gallery-item img {
    height: 245px;
  }
}

.gallery-container .gallery-item img:hover {
  transform: scale(1.1);
  cursor: pointer;
  /* opacity: 0.5; */
}

@media screen and (min-width: 768px) {
  .gallery-container .gallery-item {
    width: 40%;
    margin-bottom: 14px;
    margin-right: 7px;
  }
}

@media screen and (min-width: 1120px) {
  .gallery-container .gallery-item {
    width: 30%;
    margin-bottom: 14px;
    margin-right: 7px;
  }
}

.full-screen-gallery {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}

.full-screen-gallery .content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80vh;
  width: 70%;
  max-width: 800px;
  margin: auto;
}

@media screen and (min-width: 992px) {
  .full-screen-gallery .content {
    transform: scale(1.2);
  }
}

.full-screen-gallery-arrow {
  color: white;
  cursor: pointer;
  z-index: 10;
}

.fa-chevron-left {
  margin-right: 10px;
}

.fa-chevron-right {
  margin-left: 10px;
}

.full-screen-gallery img {
  max-height: 80vh;
  max-width: 100%;
}

.full-screen-gallery-close {
  position: absolute;
  top: 10px;
  /* adjust as needed */
  right: 10px;
  /* adjust as needed */
  color: white;
  cursor: pointer;
  z-index: 10;
}

.full-screen-gallery-close::before {
  color: white;
}

.gallery-page-filter {
  align-items: center;
  border-radius: 12px;
  background: var(--color-black-matte);
  display: flex;
  justify-content: center;
  margin: 20px auto;
  margin-top: 24px;
  padding: 20px;
  width: 70%;
}

@media screen and (min-width: 992px) {
  .full-screen-gallery .content {
    transform: scale(1.2);
  }
}