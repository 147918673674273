:root {
  --theme-color: #be9a78;
  --color-red-1: #ff9999;
  --color-green-1: #99ff99;
  --color-black-matte: #3c3c3c;
  --box-shadow-1: 4px 4px 20px rgba(0, 0, 0, 0.1);
}

.theme-color {
  color: var(--theme-color);
}

.box-shadow {
  box-shadow: var(--box-shadow-1);
}
