.add-new-voucher-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-new-voucher-modal__content {
  background-color: white;
  width: 70%;
  padding: 40px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 101;
  overflow-y: auto;
}

.add-new-voucher-modal__content h1,
.add-new-voucher-modal__content p {
  text-align: center;
}

.checkout-error {
  color: var(--color-red-1) !important;
  margin-top: 18px;
}

.payment-form .form-field input {
  width: 100%;
}
