.review-wrapper {
  padding: 10px 30px;
  transition: all 0.3s;
  border-bottom: 2px solid var(--theme-color);
}

.review-wrapper .quote p {
  color: #556482;
  margin-bottom: 0 !important;
}

.review-wrapper .rating ul {
  margin-bottom: 20px;
  display: flex;
}
.review-wrapper .rating ul li {
  margin-right: 5px;
}

.review-wrapper .rating ul li i {
  color: #e0a403;
}

.review-wrapper .rating ul li .fi:before {
  font-size: 20px;
}

.client-text {
  overflow: hidden;
  margin-left: 5px;
}

.quote {
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.client-text h5 {
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
}
