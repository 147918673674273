.admin-login-page {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.login-form {
  width: 500px;
  max-width: 90%;
  margin: auto;
  background: #fff;
  padding: 70px 50px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-form form {
  width: 350px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-form h4 {
  text-align: center;
  margin-bottom: 12px;
}

.login-form input {
  margin: 0;
  margin-bottom: 7px;
  width: 250px;
}
