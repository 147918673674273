.vouchers-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.vouchers-table {
  /* width: 75%; */
  margin-top: 25px;
}

.vouchers-table th,
.vouchers-table td {
  border: 1px solid var(--theme-color);
  padding: 5px;
  text-align: center;
  max-width: 40vw;
}

.add-new-voucher-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-new-voucher-modal .datepicker {
  text-align: center;
}

.add-new-voucher-modal__date-selector .react-datepicker__input-container {
  width: 100px !important;
}

.add-new-voucher-modal__content {
  background-color: white;
  width: 500px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 101;
  overflow-y: auto;
  position: relative;
  min-height: 500px;
}

.add-new-voucher-modal__content .date-picker {
  top: 25%;
}

.add-new-voucher-modal__date-selector {
  position: relative;
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-new-voucher-modal__date-selector i::before {
  font-size: 18px;
  margin-right: 5px;
}

.add-new-voucher-modal__date-selector span {
  position: absolute;
  margin-left: 20px;
  top: 50%;
}

.add-new-voucher-modal__date-selector .react-datepicker-wrapper input {
  border: 0;
  color: #283a5e;
}

.add-new-voucher-modal__normal-input {
  background-color: #f8f8f8;
  width: 100%;
  height: 50px;
  border: 1px solid #f8f8f8;
  padding: 6px 15px;
  outline: 0;
  box-shadow: none;
  transition: all 0.3s;
  margin-bottom: 14px;
  margin-top: 8px;
}

.add-new-voucher-modal__normal-input:focus {
  outline: none !important;
  border: 1.5px solid var(--theme-color);
}

.create-voucher-page {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.delete-voucher-button {
  background-color: #f16464;
  margin: 0 7px;
}

.add-new-voucher-modal__valid-p {
  margin: 0;
  margin-top: 10px;
}

.add-new-voucher-modal__datepicker-wrapper {
  margin: 0 auto;
  width: fit-content;
}

.create-voucher-button {
  margin: 0 auto;
  display: block;
}
