.add-review-form-container {
  margin-top: 38px;
}

.add-review-header {
  padding-bottom: 24px;
  text-align: center;
}

.single-star-wrapper i::before {
  color: #be9a78;
}

.stars-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.stars-container ul {
  display: flex;
}

.display-reviews {
  position: relative;
}

.display-reviews h2 {
  text-align: center;
  margin-top: 38px;
  font-family: "Playfair Display";
  margin: 40px auto 20px auto;
  padding-bottom: 20px;
}

.display-reviews::after {
  content: "";
  background-color: var(--theme-color);
  height: 1px;
  position: absolute;
  bottom: 0;
  width: 300px;
  left: calc(50% - 150px);
}

.review-text {
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 1.5em;
  color: #111111;
  font-weight: 600;
  font-family: "Playfair Display";
  text-align: center;
  margin-bottom: 20px;
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.review-sender {
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  font-family: "Roboto";
  margin: 0px 0px 15px;
  font-weight: 500;
}

.single-review-box {
  border-bottom: 1px solid var(--theme-color);
}

.review-swiper {
  padding: 50px !important;
}
