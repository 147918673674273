.text-editor {
  width: 100%;
  margin: 0 auto;
}

.quill {
  margin-bottom: 20px;
  width: 300px;
}

.ql-editor {
  min-height: 100px;
  border: none;
  font-family: "Montserrat", sans-serif;
  height: auto;
}

.ql-toolbar.ql-snow {
  background-color: #ffffff;
  box-shadow: var(--box-shadow-1);
  border: none;
}

.ql-container {
  font-size: normal;
}

.ql-container.ql-snow {
  border-radius: 1rem;
}

.ql-toolbar.ql-snow .ql-picker-label {
  display: flex;
}

.ql-editor p,
.ql-editor li,
.ql-editor a {
  font-size: 1.2rem;
  line-height: 2rem;
}

.ql-editor h1 {
  font-size: 2.2rem;
  line-height: 3rem;
}

.ql-editor h2 {
  font-size: 1.7rem;
  line-height: 2.5rem;
}

.ql-editor p strong {
  font-weight: bold;
}

.ql-editor blockquote {
  border-left: 4px solid #979797;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

@media screen and (min-width: 768px) {
  .quill {
    width: 600px;
  }
}

@media screen and (min-width: 998px) {
  .quill {
    width: 800px;
  }
}

@media screen and (min-width: 1300px) {
  .quill {
    width: 1000px;
  }
}
