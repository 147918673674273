.all-reviews-page-container {
  min-height: 100vh;
  width: 100%;
  margin-bottom: 100px;
}

.all-reviews-page-container h3 {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 28px;
  position: relative;
}

.all-reviews-page-container h3::after {
  content: "";
  width: 250px;
  position: absolute;
  bottom: -10px;
  background-color: var(--theme-color);
  height: 1px;
  left: calc(50% - 125px);
}

.admin-display-reviews-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.admin-display-reviews-container .review-wrapper {
  max-width: 30%;
  min-width: 200px;
  margin-bottom: 32px;
}

.review-admin-buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
}

.review-admin-buttons button {
  height: 40px;
  width: auto;
  min-width: 50px;
  font-size: 16px;
}

.review-admin-buttons .delete-button {
  background: #f16464;
  margin-left: 5px;
}

@media screen and (min-width: 768px) {
  .admin-display-reviews-container .review-wrapper {
    margin-right: 20px;
    min-width: 320px;
  }
}
